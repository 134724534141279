import React, { useState, useEffect} from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ImgLine from "../../images/modal-line.png"
import InputMask from "react-input-mask"
import { useForm } from "react-hook-form"
import { setUser } from "../../utils/auth"
import { navigate } from "gatsby"
import {useCookies} from "react-cookie"

export default function RegisterPage(props) {
  const [cookies] = useCookies()
  const promoCode = cookies['pcode']

  const {
    register,
    handleSubmit,
    errors,
    formState,
    setError,
    clearErrors,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
  })

  const [acceptAgreement, setAcceptAgreement] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)

  useEffect(() => {
    if (isSubmitted) {
      navigate("/register/activate/")
    }
  })


  const onSubmit = form => {
    const details = {
      email: form.email,
      name: form.name,
      phone: form.phone,
      site: form.site,
      promoCode: promoCode,
      isLargeVolumePackage: form.isLargeVolumePackage
    }

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(details),
    }

    const setFlashServerError = (timeout) => {
      setError("result", {
        type: "manual",
        message: "Ошибка сервера. Повторите попытку позже.",
      })

      setTimeout(() => {
        clearErrors("result")
      }, timeout || 3000)
    }

    fetch("/api/user/register", requestOptions)
      .then(response => {

        if (response.ok) {
          return response.json()
            .then(data => {
              if (data.result === "success") {
                setUser({
                  phone: form.phone,
                  email: form.email,
                  name: form.name,
                  site: form.site,
                  promoCode: promoCode,
                  isLargeVolumePackage: form.isLargeVolumePackage
                })

                setIsSubmitted(true)
                return
              }
              throw Error()
            })
        }

        if (response.status === 422) { // server side validation errors
          return response.json()
            .then(errors => {
              for (const error in errors) {
                if (errors.hasOwnProperty(error)) {
                  setError(error, {
                    type: "manual",
                    message: errors[error],
                  })
                }
              }
            })
        }

        // unexpected server errors
        throw Error()

      })
      .catch(() => setFlashServerError()) // catch all server errors
  }

  const { isValid } = formState

  return (
    <Layout location={props.location}>
      <SEO title="Регистрация в личном кабинете"/>
      <section className="register-content">
        <div className="container">
          <>
            <div className="register-box">
              <div className="register__head">
                <h2>Регистрация</h2>
                <img className="register__head_img" src={ImgLine} alt=""/>
              </div>
              <form className="register-form" onSubmit={handleSubmit(onSubmit)}>
                <div
                  className={`register-form__field`}>
                  <label htmlFor="ct_name" className="register-form__label">
                    Ваше имя
                  </label>
                  <input
                    defaultValue=""
                    id="ct_name"
                    className="register-form__input"
                    type="text"
                    name="name"
                    placeholder=""
                    ref={register({
                      required: "Необходимо заполнить",
                      pattern: {
                        value: /^[a-zа-яё\s]+$/iu,
                        message: "Может содержать только буквы",
                      },
                    })}
                  />
                  <div className="formErrors">
                    <p className="register-form__error">
                      {errors.name?.message}
                    </p>
                  </div>
                </div>

                <div className={`register-form__field ${errors.phone ? "has-error" : ""}`}>
                  <label htmlFor="ct_tel" className="register-form__label">
                    Телефон
                  </label>
                  <InputMask
                      mask="+7 (999) 999-99-99"
                      alwaysShowMask
                      name="phone"
                      type="tel"
                      className="register-form__input"
                      id="ct_tel"
                      ref={register({
                        required: "Необходимо заполнить телефон",
                        pattern: {
                          value: /(\(\d{3}\))(\s)\d{3}-\d{2}-\d{2}/,
                          message: "Неверный формат номера телефона",
                        },
                      })}
                  />
                  <div className="formErrors">
                    <p className="register-form__error">
                      {errors.phone?.message}
                    </p>
                  </div>
                </div>

                <div className={`register-form__field ${errors.email ? "has-error" : ""}`}>
                  <label htmlFor="ct_email" className="register-form__label">
                    Email
                  </label>
                  <input
                    name="email"
                    type="email"
                    className="register-form__input"
                    id="ct_email"
                    placeholder=""
                    ref={register({
                      required: "Необходимо заполнить",
                      pattern: {
                        value: /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i,
                        message: "Неверный формат email адреса",
                      },
                    })}
                  />
                  <div className="formErrors">
                    <p className="register-form__error">
                      {errors.email?.message}
                    </p>
                  </div>
                </div>

                <div className={`register-form__field ${errors.site ? "has-error" : ""}`}>
                  <label htmlFor="ct_site" className="register-form__label">
                    Сайт магазина
                  </label>
                  <input
                    name="site"
                    type="text"
                    className="register-form__input"
                    id="ct_site"
                    placeholder=""
                    ref={register({
                      required: "Необходимо заполнить",
                    })}
                  />

                  <div className="formErrors">
                    <p className="register-form__error">
                      {errors.site?.message}
                    </p>
                  </div>
                </div>

                <div className="s-radio">
                  <div className="s-radio__list">
                    <div className="s-radio__block">
                      <input
                          name="isLargeVolumePackage"
                          id="quantity_parcels_more"
                          type="radio"
                          value={true}
                          ref={register({ required: true })}
                      />
                      <label htmlFor="quantity_parcels_more">Да</label>
                    </div>
                    <div className="s-radio__block">
                      <input
                          name="isLargeVolumePackage"
                          id="quantity_parcels_smaller"
                          type="radio"
                          value={false}
                          ref={register({ required: true })}
                      />
                      <label htmlFor="quantity_parcels_smaller">Нет</label>
                    </div>
                  </div>
                  <div className="s-radio__text">
                    <div className="s-radio__block">
                      <span className="s-radio__text">У меня больше 1000 отправлений</span>
                    </div>
                  </div>
                </div>

                <div className="s-checkbox">
                  <input
                    type="checkbox"
                    id="privacy_statement"
                    name="acceptAgreement"
                  />
                  <label
                    htmlFor="privacy_statement"
                    className={`s-checkbox__label ${acceptAgreement ? "active" : ""}`}
                    onClick={() => setAcceptAgreement(!acceptAgreement)}
                    role="presentation"
                  >
                    <a
                      className="s-checkbox__link link_default"
                      href="/policy"
                      target="_blank"
                    >
                      Я согласен с положением о конфиденциальности
                      персональных данных
                    </a> и <a
                      className="s-checkbox__link link_default"
                      href="/user_agreement.pdf"
                      target="_blank"
                    >
                      с условиями пользовательского соглашения
                    </a>
                  </label>
                </div>

                <div className="formErrors">
                  <p className="register-form__error">
                    {errors.result?.message}
                  </p>
                </div>

                <button
                  type="submit"
                  className="btn-overall register-form__btn"
                  data-modal="#complete"
                  disabled={!isValid || !acceptAgreement}
                >
                  Отправить
                </button>
              </form>
            </div>
          </>
        </div>
      </section>
    </Layout>
  )
}